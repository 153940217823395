import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/convert-layout"
import SEO from "../../components/seo"
import useScript from "../../hooks/useScript";

const JsonToCsvPage = () => {
  const [inputError, setInputError] = useState('');
  const [inputMirror, setInputMirror] = useState<CodeMirror.EditorFromTextArea>();
  const [outputMirror, setOutputMirror] = useState<CodeMirror.EditorFromTextArea>();
  const inputModeStatus = useScript('https://cdn.jsdelivr.net/npm/codemirror@5.63.3/mode/xml/xml.min.js');
  const outputModeStatus = useScript('https://cdn.jsdelivr.net/npm/codemirror@5.63.3/mode/javascript/javascript.min.js');

  useEffect(() => {
    const inputTextarea = document.getElementById('code-input') as HTMLTextAreaElement;
    const outputTextarea = document.getElementById('code-output') as HTMLTextAreaElement;
    const inputMirror: any = window.CodeMirror.fromTextArea(inputTextarea, {
      lineNumbers: true,
      mode: 'xml',
    });

    inputMirror.setSize("100%", "100%");

    const outputMirror = window.CodeMirror.fromTextArea(outputTextarea, {
      lineNumbers: true,
      mode: 'application/json',
    });

    outputMirror.setSize("100%", "100%");

    inputMirror.on('change', (cm) => {
      try {
        const xml = cm.getValue();

        // @ts-ignore
        const json = xml2json(xml, {compact: true, nativeType: true, spaces: 2});
        outputMirror.setValue(json);
      } catch (e) {
        console.error(e);
        setInputError('Error converting XML');
      }
    });

    setInputMirror(inputMirror);
    setOutputMirror(outputMirror);
  }, []);

  useEffect(() => {
    if (inputModeStatus === 'ready' && inputMirror) {
      inputMirror.setOption('mode', 'xml');
    }
  }, [inputModeStatus, inputMirror]);

  useEffect(() => {
    if (outputModeStatus === 'ready' && outputMirror) {
      outputMirror.setOption('mode', 'application/json');
    }
  }, [outputModeStatus, outputMirror]);

  return (
    <Layout activeGroup="convert" secondHeaderText="Convert - XML to JSON">
      <SEO title="XML to JSON" description="Convert data from xml to json free" />
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/xml-js@1.6.11/dist/xml-js.min.js"></script>
      </Helmet>
      <div className="grid grid-cols-12 gap-4 h-screen">
        <div className="col-span-6 h-3/4" style={{border: '1px solid lightgrey'}}>
          <textarea id="code-input"></textarea>
          {inputError && <p className="text-red-600 text-center pt-5">{inputError}</p>}
        </div>
        <div className="col-span-6 h-3/4" style={{border: '1px solid lightgrey'}}>
          <textarea id="code-output"></textarea>
        </div>
      </div>
    </Layout>
  )
}

export default JsonToCsvPage
